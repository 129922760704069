<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      no-filter
      rounded
      filled
      class="ml-2"
      style="width: 25em"
      hide-selected
      @change="loadDIP(model)"
      label="Load an existing DIP..."
      placeholder="Enter a project name or number to search"
      return-object
    >
      <template slot="prepend-inner">
        <v-icon>mdi-magnify</v-icon>
      </template>

      <template slot="selection" slot-scope="data" v-if="redirect">
        <span
          v-text="
            (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
            '-' +
            data.item.ProjectName
          "
        ></span>
      </template>
      <template slot="item" slot-scope="data">
        <span
          v-text="
            data.item.Type +
            ' - ' +
            (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
            ' - ' +
            data.item.ProjectName
          "
        >
        </span>
        <v-spacer />
        <v-icon right v-if="getLockStatus(data.item) == true">
          mdi-lock
        </v-icon>
      </template>
    </v-autocomplete>

    <v-overlay :value="loadingSpinnerState">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="errorMessage"
      max-width="500"
      @click:outside="errorMessage = null"
      @keydown.escape="errorMessage = null"
    >
      <v-card>
        <v-card-title>An error occured</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="errorMessage = null">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="warningDialog" max-width="500">
      <div align="center">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col> <h3>This DIP is locked!</h3></v-col>
            </v-row>

            <v-row>
              <v-col>
                Please request to be added to the DIP from the following list of
                Arupians:
              </v-col>
            </v-row>
            <div v-for="person in attendanceList" v-bind:key="person">
              <v-row>
                <v-col>
                  <a :href="'mailto:' + person">{{ person }}</a>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  data: () => ({
    entries: [],
    isLoading: false,
    loadingSpinnerState: false,
    redirect: false,
    errorMessage: null,
    model: null,
    search: null,
    loading: false,
    attendanceList: [],
    planAccess: null,
    warningDialog: false,
    lastModified: null,
    loadedStore: null,
    planType: null,
    loadPlanOutcomes: null,
  }),

  computed: {
    items() {
      return this.entries;
    },
  },

  watch: {
    search(value) {
      if (!value || value.length < 3) {
        return;
      }
      this.searchDebounce(value);
    },
  },
  methods: {
    getLockStatus(item) {
      if (item.hasOwnProperty("DigitalPlan")) {
        if (item.hasOwnProperty("Lock")) {
          if (item.DigitalPlan.Lock) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      if (item.hasOwnProperty("PreBidPlan")) {
        if (item.hasOwnProperty("Lock")) {
          if (item.PreBidPlan.Lock) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    async loadDIP(model) {
      this.loadingSpinnerState = true;
      this.redirect = true;
      try {
        this.loadPlanOutcomes = await this.loadGenericPlan(
          this.$store.state.ApiUri,
          model,
          this.$store.state
        ); // loadDIP holds full dip data

        console.log("test");
        console.log(this.loadPlanOutcomes);

        this.planAccess = this.loadPlanOutcomes.planAccess;
        this.warningDialog = this.loadPlanOutcomes.showWarningDialog;
        this.lastModified = this.loadPlanOutcomes.lastModified;
        this.loadedStore = this.loadPlanOutcomes.loadedStore;
        this.planType = this.loadPlanOutcomes.type;
        this.attendanceList = this.loadPlanOutcomes.attendanceList;

        if (this.planAccess) {
          if (this.planType === "DIP") {
            this.$session.set("planData", this.loadedStore);
            this.$store.state.plan = this.loadedStore;
            this.$store.state.lastModified = this.lastModified;
            this.$router.push({ name: "Preparation" });
          } else if (this.planType === "PREBID") {
            this.$session.set("planData", this.loadedStore);
            this.$store.state.prebidPlan = this.loadedStore;
            this.$store.state.lastModified = this.lastModified;
            this.$router.push({ name: "PrebidForm" });
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        this.errorMessage = error;
      }
      this.loadingSpinnerState = false;
      this.redirect = false;
    },

    searchDebounce(value) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.doSearch(value, this);
      }, 500);
    },
    async doSearch(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.entries = [];

      try {
        const [dipResults, prebidResults] = await Promise.all([
          this.$dip.get(
            `${this.$store.state.ApiUri}/GetResults?Keyword=${val}&DataLevel=Overview` // change for Jobs!
          ),
          this.$dip.get(
            `${this.$store.state.ApiUri}/GetPreBidResults?Keyword=${val}&DataLevel=Overview`
          ),
        ]);

        var dipEntries = [];
        var prebidEntries = [];

        // Check if dipResults.data contains "No data found" string
        if (dipResults.data !== "No results found.") {
          // If not, proceed to map and add the Type property
          dipEntries = dipResults.data.map((x) => {
            x.Type = "DIP";
            return x;
          });
        }

        // Check if prebidResults.data contains "No data found" string
        if (prebidResults.data !== "No results found.") {
          // If not, proceed to map and add the Type property
          prebidEntries = prebidResults.data.map((x) => {
            x.Type = "Prebid";
            return x;
          });
        }
        this.entries = dipEntries.concat(prebidEntries);
      } catch (err) {
        // console.log(err);
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style src="../styles/forms.scss" lang="scss">
</style>