var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{staticClass:"ml-2",staticStyle:{"width":"25em"},attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"no-filter":"","rounded":"","filled":"","hide-selected":"","label":"Load an existing DIP...","placeholder":"Enter a project name or number to search","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.loadDIP(_vm.model)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return (_vm.redirect)?[_c('span',{domProps:{"textContent":_vm._s(
          (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
          '-' +
          data.item.ProjectName
        )}})]:undefined}},{key:"item",fn:function(data){return [_c('span',{domProps:{"textContent":_vm._s(
          data.item.Type +
          ' - ' +
          (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
          ' - ' +
          data.item.ProjectName
        )}}),_c('v-spacer'),(_vm.getLockStatus(data.item) == true)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock ")]):_vm._e()]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],2),_c('v-overlay',{attrs:{"value":_vm.loadingSpinnerState}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":function($event){_vm.errorMessage = null},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }_vm.errorMessage = null}},model:{value:(_vm.errorMessage),callback:function ($$v) {_vm.errorMessage=$$v},expression:"errorMessage"}},[_c('v-card',[_c('v-card-title',[_vm._v("An error occured")]),_c('v-card-text',[_vm._v(_vm._s(_vm.errorMessage))]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.errorMessage = null}}},[_vm._v(" Close ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.warningDialog),callback:function ($$v) {_vm.warningDialog=$$v},expression:"warningDialog"}},[_c('div',{attrs:{"align":"center"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("This DIP is locked!")])])],1),_c('v-row',[_c('v-col',[_vm._v(" Please request to be added to the DIP from the following list of Arupians: ")])],1),_vm._l((_vm.attendanceList),function(person){return _c('div',{key:person},[_c('v-row',[_c('v-col',[_c('a',{attrs:{"href":'mailto:' + person}},[_vm._v(_vm._s(person))])])],1)],1)})],2)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }