<template>
  <!-- <div v-if="!maintenanceActive" class="v-system-bar"> -->
  <nav
    style="
      display: flex;
      padding: 10px;
      align-items: flex-start;
      justify-content: space-between;
      margin: auto;
      width: 95%;
    "
  >
    <v-img
      alt="Arup Logo"
      src="../assets/logo-home-new--red.svg"
      class="shrink logo"
      style="margin: auto; cursor: pointer"
      transition="scale-transition"
      width="80"
      @click="navigate('Home')"
    />
    <v-img
      alt="DIP"
      src="../assets/DIP.svg"
      class="shrink logo"
      style="margin-left: 10px; cursor: pointer"
      transition="scale-transition"
      width="80"
      @click="navigate('Home')"
    />

    <v-divider class="mx-2 grey darken-5" vertical />

    <DipSearch />
    <v-spacer />

    <div
      style="
        justify-content: center;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-top: 5px;
      "
    >
      <v-btn
        target="_blank"
        plain
        @click="disableRedirect"
        class="v-btn-nav"
        href="https://app.powerbi.com/links/aMR79UCF1_?ctid=4ae48b41-0137-4599-8661-fc641fe77bea&pbi_source=linkShare"
      >
        DIP STATS</v-btn
      >

      <v-btn
        target="_blank"
        plain
        @click="disableRedirect"
        class="v-btn-nav"
        :href="whiteboardTemplateLink"
      >
        Workshop Template</v-btn
      >

      <v-btn target="_blank" plain @click="navigate('Help')" class="v-btn-nav">
        Help
      </v-btn>

      <v-btn
        target="_blank"
        plain
        @click="disableRedirect"
        class="v-btn-nav"
        href="https://feedback.arup.com/digital-inception-pl/35#"
      >
        Feedback</v-btn
      >
      <v-btn
        target="_blank"
        plain
        @click="disableRedirect"
        class="v-btn-nav"
        href="https://moodle.arup.com/course/view.php?id=5841%C2%A7ion%3D7"
      >
        DIP Training
      </v-btn>
    </div>

    <!-- 
    <div>
      <i class="v-btn-user"> </i>
      <span class="pl-5 hidden-sm-and-down">{{ login }}</span>
    </div> -->

    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="dialog = false"
    >
      <v-card>
        <v-card-title class="headline">DIP Dashboard</v-card-title>

        <v-card-text>
          The DIP dashboard is currently down for maintenance.
        </v-card-text>
      </v-card>
    </v-dialog>
  </nav>
</template>

<script>
import mixin from "../mixins/mixin.js";
import DipSearch from "@/components/DipSearch.vue";

export default {
  mixins: [mixin],
  name: "App",

  components: {
    DipSearch,
  },

  data: () => ({
    login: "Not logged in.",
    dashboardDisabled: false, // set this to true to disable the dashboard button in the apps header
    dialog: false,
  }),
  created() {
    this.login = "Welcome " + this.$msal.getUser().name;
  },

  computed: {
    whiteboardTemplateLink: {
      get: function () {
        return this.$store.getters.getWhiteboardTemplateLink;
      },
    },
    maintenanceActive() {
      return this.$store.state.maintenanceMode === "true";
    },
  },

  methods: {
    navigate(routeName) {
      // this.fullUpdateDIP(this.$store.state.plan, this.$store.state.ApiUri);
      console.log(`navigation to ${routeName}...`);
      this.$router.push({ name: `${routeName}` });
    },
    disableRedirect(event) {
      if (this.dashboardDisabled) {
        event.preventDefault();
        this.dialog = true;
      }
    },
  },
};
</script>

<style src="../styles/main.scss" lang="scss"></style>
